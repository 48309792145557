.message-warp {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.message-box {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 21.333333rem;
  max-height: 10.666667rem;
}

.message-box .msg-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message-box .msg-content .el-icon-success {
  margin-bottom: 0.426667rem;
  font-size: 2.133333rem;
  color: #67c23a;
}

.message-box .msg-content .msg-title {
  font-size: 0.693333rem;
  font-weight: 600;
  color: #333333;
  line-height: 1.066667rem;
  text-align: center;
}

.message-box .msg-content .split-line {
  margin: 0.533333rem 0 0.426667rem;
  width: 100%;
}

.message-box .msg-content .split-line::before {
  content: ' ';
  margin: 0 auto;
  display: block;
  width: 0.853333rem;
  height: 0.106667rem;
  background: #1990ff;
}

.message-box .msg-content .message {
  text-align: center;
}