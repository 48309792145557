.satisfaction {
  width: 100vw;
  height: calc(100vh - 1.706667rem);
  background: #f8f8fa;
  overflow-x: hidden;
}

.satisfaction .satisfaction-head {
  width: 100vw;
  display: flex;
  align-items: center;
  padding-left: 10%;
  height: 1.706667rem;
  line-height: 1.706667rem;
  background: #1990ff;
  box-shadow: inset 0 -0.026667rem 0 0 #eeeeee;
  backdrop-filter: blur(0.106667rem);
}

.satisfaction .satisfaction-body {
  margin: 0.64rem auto 0;
  padding: 0.746667rem;
  max-width: 21.333333rem;
  width: 100vw;
  min-height: 100vh;
  background: #ffffff;
}

.satisfaction .satisfaction-body .message {
  font-size: 0.533333rem;
  font-weight: 600;
  color: #333333;
}

.satisfaction .satisfaction-body .split-line {
  margin-top: 0.533333rem;
  width: 0.853333rem;
  height: 0.106667rem;
  background: #1990ff;
}

.satisfaction .satisfaction-body .section {
  margin-top: 0.853333rem;
}

.satisfaction .satisfaction-body .section .title {
  font-size: 0.426667rem;
  font-weight: 600;
  color: #111111;
}

.satisfaction .satisfaction-body .section .desc {
  font-size: 0.32rem;
  font-weight: 400;
  color: #666666;
}

.satisfaction .satisfaction-body .section .field-warp {
  margin-top: 0.213333rem;
}

.satisfaction .satisfaction-body .section .field-warp .form-rate {
  display: flex;
  align-items: center;
  border-bottom: 0.026667rem solid #eeeeee;
}

.satisfaction .satisfaction-body .section .field-warp .form-rate /deep/.el-form-item__label {
  min-width: 4rem;
  width: 100%;
  padding: 0;
}

.satisfaction .satisfaction-body .section .field-warp .form-rate /deep/.el-form-item__content {
  display: flex;
  justify-content: end;
  flex-shrink: 0;
}

.satisfaction .satisfaction-body .footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.693333rem;
  height: 1.066667rem;
}